import React, {Component} from 'react'
import { analytics } from '../../firebase.js'
import PackCard from './PackCard'
import PackView from './PackView'
import './PackContainer.css'

class PackContainer extends Component {
    static defaultProps = {
        packs: [],
        topics: []
    }

    constructor(props) {
        super(props)
        this.state = {
            selected: null,
        }
        this.onSelect = this.onSelect.bind(this)
    }

    onSelect(index) {
        var title = '';
        if(index !== null && index > this.props.packs.length-1){
            title = this.props.featured[index - this.props.packs.length].title
        } else if(index !== null) {
            title = this.props.packs[index].title
        }
        analytics.logEvent('select_content', {
            content_type: 'pack',
            content_id: title,
        });
        this.setState({
            selected: index
        })
    }

    render() {
        if (this.props.packs.length === 0) {
            return (
                <div>
                    Loading...
                </div>
            )
        } else if (this.state.selected === null) {
            return (
                <div>
                    {/* Full Library */}
                    <p className="explore-title">Singles</p>
                    <p>Quick, repeatable prompts.</p>
                    <div className="pack-container">
                        
                        {this.props.packs.map((pack, i) => {
                            return (
                                <div key={i}>
                                    <PackCard id={i} pack={pack} onSelect={this.onSelect.bind(this)}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="pack-container">
                    <PackView backButton={this.onSelect.bind(this)} pack={this.props.packs[this.state.selected]}/>
                </div>
            )
        }
    }
}

export default PackContainer