import React, {Component} from 'react'
import { db } from '../../firebase.js'
import TopicCard from './TopicCard.js';
import '../../App.css';
import './Topics.css'

class Topics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topics: [],
        }
    }

    componentDidMount(){
        db.collection('topics').get().then((snapshot) => {
            let library = snapshot.docs.map((topic) => {
                return topic.data()
            })
            
            this.setState({
                topics: library,
            })
        })
    }

    render() {
        return (
            <div>
                <p className="title">Conversation Library</p>
                <p>Fresh questions on every day topics.</p>
                <div className="topic-container">
                    {this.state.topics.map((topic, i) => (
                        <TopicCard key={i} topic={topic} onClick={this.props.onClick} />
                    ))}
                </div>
            </div>
        )
    }

}

export default Topics