import React, {Component} from 'react'
import { auth, db, analytics } from '../../firebase.js'
import {ReactComponent as StreakIcon} from './flame.svg'
import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8'
import Card from 'react-bootstrap/Card'
import moment from 'moment'
import '../../App.css';
import './RecentEntries.css'

class RecentEntries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entries: [],
            streak: 0,
        }
    }

    componentDidMount(){
        var user = auth.currentUser;
        var uid = user.uid;

        var currentDate = new Date();
        var oldDate = new Date();
        oldDate.setDate(oldDate.getDate() - 7);

        db.collection('users').doc(uid).collection('entries')
            .orderBy("date", "desc")
            .startAt(currentDate)
            .endAt(oldDate)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) =>  {
                    let type = doc.data().type;
                    if(type === 'daily-question'){
                        type = 'Daily Conversation';
                    } else if(type==='free-thought'){
                        type = 'Free Thought';
                    } else {
                        type = doc.data().title;
                    }
                    let entries = doc.data().responses;
                    entries.forEach(entry => {
                        entry.response = AES.decrypt(entry.response.toString(), uid).toString(enc)
                    })
                    let raw_date = doc.data().date.toDate();
                    let human_date = moment(raw_date).fromNow();
                    this.setState(prevState => ({
                        entries: prevState.entries.concat({type: type, entries: entries, date: human_date, id: doc.id}),
                    }));
                });
            })

        db.collection('users').doc(auth.currentUser.uid)
        .get()
        .then((docSnapshot) => {
            if(!docSnapshot.exists){
                analytics.logEvent('sign_up')
                db.collection('users').doc(auth.currentUser.uid).set({
                    accountCreation: new Date(),
                    streak: 0,
                    mostRecentDate: '',
                })
            } else {
                if(docSnapshot.get('mostRecentDate') !== null){
                    let lastDate = docSnapshot.data().mostRecentDate
                    let today = moment().format('MMMM Do YYYY')
                    let yesterday = moment().subtract(1,'days').format('MMMM Do YYYY')
                    if(lastDate === today || lastDate === yesterday){
                        this.setState({
                            streak: docSnapshot.data().streak,
                        })
                    } else {
                        docSnapshot.ref.set({
                            streak: 0,
                        })
                    }
                } else {
                    docSnapshot.ref.set({
                        streak: 0,
                        mostRecentDate: ''
                    })
                }
                
            }
        })
    }

    render() {
        if(this.state.entries.length) {
            return (
                <div className="recent-entries">
                    <div className="recent-entries-title-bar">
                        <p className="title">Recent Entries</p>
                        <div className="streak-icon">
                            <StreakIcon className="flame" />
                            <p className="streak-count-text">{this.state.streak}</p>
                        </div>
                    </div>
                    {this.state.entries.map(entry => (
                        <div className="entry" key={entry.id}>
                            <Card className="custom-card" onClick={() => this.props.onClick(3, entry)}>
                                <div className='entry-info'>
                                    <div className="entry-title">{entry.type}</div>
                                    <div className="entry-date">{entry.date}</div>
                                </div>
                            </Card>
                        </div>
                    ))}
                </div>
            )
        } else {
            return (
                <div className="recent-entries">
                    <p className="title">Recent Entries</p>
                    <p>Entries written in the last week will appear here.</p>
                </div>
            )
        }
    }

}

export default RecentEntries