import React, {Component} from 'react'
import { db } from '../../firebase.js';
import { Lottie } from '@crello/react-lottie'
import animation from './PostEntry.json'
import MoodMusic from './MoodMusic'
import '../../App.css'
import './ReportCard.css'

class ReportCard extends Component {
    constructor(props){
        super(props)
        this.state = {
            mode: this.props.mode,
            title: this.props.title,
            topic: this.props.topic,
            quote: "",
            author: "",
        }
    }

    componentDidMount(){
        if(this.state.mode === "mini-pack" && this.state.title !== 'Random'){
            db.collection('mini-packs')
            .where('title', '==', this.state.title)
            .get()
            .then((snapshot) => {
                let doc = snapshot.docs[0];
                if(doc){
                    this.setState({
                        quote: doc.data().quote,
                        author: doc.data().author,
                    })
                }
            })
        } else {
            db.collection('quotes')
            .where('topic', '==', this.props.topic)
            .get()
            .then((snapshot) => {
                let random = Math.floor(Math.random()*snapshot.docs.length)
                let doc = snapshot.docs[random];
                if (doc) {
                    this.setState({
                        quote: doc.data().quote,
                        author: doc.data().author,
                    })
                }
            });
        }
    }

    render(){
        return (
            <div style={{width: "100%"}}>
                <div className="report-card">
                    <div className="post-entry-animation">
                        <Lottie
                            config={{
                                animationData: animation,
                                autoplay: true,
                            }}
                        />
                    </div>
                    <p>{this.state.quote}</p>
                    <p>{this.state.author}</p>
                    <button className="atb-3d-b atb-small atb-round atb-danger" onClick={() => this.props.onClick(0)}>Done</button>
                </div>
                <hr/>
                <MoodMusic id={this.props.id}/>
            </div>
        )
    }
}

export default ReportCard