import React, {Component} from 'react';
import { auth, db, firestore, analytics } from '../../firebase.js';
import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8'
import TextareaAutosize from 'react-textarea-autosize';
import ReportCard from '../post-entry/ReportCard.js'
import moment from 'moment'
import '../../App.css'
import './EntryFlow.css'

function progress(stepNum) {
    let steps = document.querySelectorAll('.step')
    steps.forEach((e) => {
        if (e.id === stepNum) {
            e.classList.add('selected');
            e.classList.remove('completed');
        }
        if (e.id < stepNum) {
            e.classList.add('completed');
        }
        if (e.id > stepNum) {
            e.classList.remove('selected', 'completed');
        }
    });
}

class EntryFlow extends Component {
    constructor(props){
        super(props);
        this.state = {
            mode: this.props.mode,
            questions: this.props.pack.questions,
            title: this.props.pack.title ? this.props.pack.title : "Daily Mix",
            topic: '',
            index: 0,
            response: '',
            completed: false,
            buttonclass: 'atb-3d-b atb-small atb-round atb-inactive',
            entries: [],
            docid: ''
        }
        this.onClick = this.onClick.bind(this);
        this.back = this.back.bind(this);
    }

    componentDidMount(){
        if(this.props.pack.questions.length === 0){
            db.collection('daily-question')
            .get()
            .then((snapshot) => {
                let randomIndex = Math.floor(Math.random()*snapshot.docs.length)
                let doc = snapshot.docs[randomIndex]
                if(doc){
                    this.setState({
                        questions: doc.data().questions,
                        topic: doc.data().topic
                    })
                }
            })
        }
    }

    back(){
        if(this.state.index === 0){
            return this.props.onClick(0)
        } else {
            let current = this.state.index;
            progress(current-2)
            let uid = auth.currentUser.uid;
            let previousResponse = AES.decrypt(this.state.entries[current-1].response.toString(), uid).toString(enc)
            this.state.entries.splice(-1,1)
            this.setState({
                index: current - 1,
                response: previousResponse,
            })
        }
    }

    textChange = (e) => {
        if(e.target.value !== ''){
            this.setState({
                buttonclass: "atb-3d-b atb-small atb-round atb-danger"
            })
        } else {
            this.setState({
                buttonclass: "atb-3d-b atb-small atb-round atb-inactive"
            })
        }
        this.setState({response: e.target.value});
    }

    currentQuestion(){
        return this.state.questions[this.state.index]
    }

    onClick(){
        // circle progress fill
        let current = this.state.index;
        progress(current+1)

        let uid = auth.currentUser.uid;
        let question = this.state.questions[this.state.index]
        let response = AES.encrypt(this.state.response, uid).toString()
        let entries = this.state.entries.concat({'question': question, 'response': response})

        // update state
        this.setState({
            index: current + 1,
            response: '',
            buttonclass: "atb-3d-b atb-small atb-round atb-inactive",
            entries: entries,
        })

        // finished entry flow
        if(this.state.index === this.state.questions.length-1){
            // add to database
            db.collection('users').doc(uid).collection('entries').add({
                title: this.state.title,
                type: this.state.mode,
                responses: entries,
                date: new Date(),
            })
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                this.setState({
                    docid: docRef.id
                })
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });

            this.setState({
                completed: true,
            })

            analytics.logEvent('completed_entry', {
                type: 'mini-pack',
                title: this.state.title,
            });

            // increment streak count as necessary
            db.collection('users').doc(uid)
            .get()
            .then((snapshot) => {
                let date = moment().format('MMMM Do YYYY')
                // check to not update twice in same day
                if(snapshot.data().mostRecentDate !== date){
                    snapshot.ref.update({
                        streak: firestore.FieldValue.increment(1),
                        mostRecentDate: moment().format('MMMM Do YYYY'),
                    })
                }
            })
        }
    }

    render(){

        if(this.state.completed){
            return <ReportCard onClick={this.props.onClick} mode={this.state.mode} title={this.state.title} topic={this.state.topic} id={this.state.docid}/>
        }
        else
        {
            return (
                <div className="entry-flow-container">
                    <div className="title-bar-entry">
                        <span className="back-button" onClick={this.back}>
                            <img src='Back.svg' width='10px' alt="Back button icon." />
                            <span className='back-text'>Back</span>
                        </span>
                        <p className="pack-title-entry">
                            {this.state.title}
                        </p>
                        {moment().format('MMMM Do')}
                    </div>
                    <hr/>

                    <div className="bar-container">
                        <div className="steps">
                            {this.state.questions.map((question, i) => (
                                <div className="step" id={i} key={i}></div>
                            ))}
                        </div>
                    </div>
                    <p className="current-question">{this.currentQuestion()}</p>
                    <TextareaAutosize autoFocus className="textfield" placeholder="Say something..." value={this.state.response} onChange={this.textChange.bind(this)}/>
                    <button style={{marginBottom: '20px'}} disabled={!this.state.response.length} id="next" className={this.state.buttonclass} onClick={this.onClick}>{this.state.index === this.state.questions.length-1 ? <span>Done</span> : <span>Next</span>}</button>
                </div>
            )
        }
    }
}

export default EntryFlow