import React, { Component } from 'react';
import { auth } from './../firebase.js'
import { Lottie } from '@crello/react-lottie'
import animation from './loading.json'
import Profile from './profile/Profile.js'
import HomeContainer from './home/HomeContainer.js';
import './../App.css';

function ScreenSelect(props){
    const desiredScreen = props.screen;
    if(desiredScreen === "Home"){
        return <HomeContainer />  
    } else {
        return <Profile logout={props.logout}/>
    }
}

class App extends Component {
    constructor() {
      super()
      this.state = {
          user: null,
          tab: "Home",
          loading: false,
      }
    }

    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ user })
            }
        })

        // Get all the tabs
        const tabs = document.querySelectorAll('.tab');

        tabs.forEach(clickedTab => {
            // Add onClick event listener on each tab
            clickedTab.addEventListener('click', () => {
                // Remove the active class from all the tabs (this acts as a "hard" reset)
                tabs.forEach(tab => {
                    tab.classList.remove('active');
                });

                // Add the active class on the clicked tab
                clickedTab.classList.add('active');
                this.setState({
                    tab: clickedTab.getAttribute('tagname')
                })
            });
        });
    }

    logout() {
        auth.signOut()
        .then(() => {
            this.setState({
                user: null,
            })
        })
        window.location.href="https://wellnest.co/"
    }

    render() {
        return (
            <div>
                <div className="tab-container">
                    <div className="navbar">
                        <div>
                            <div className="tab active" tagname="Home">
                                <img src="logo-long.svg" width="100" alt="home button logo"/>
                            </div>
                        </div>
                        <div>
                            <div className="tab" tagname="Home">
                                <img src="home.svg" width="22" alt="home button"/>
                            </div>
                            <div style={{'marginRight': '0px'}} className="tab" tagname="Profile">
                                <img src="profile.svg" width="18" alt="profile button"/>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                { this.state.user ?
                <div className="app-container">
                    <ScreenSelect screen={this.state.tab} logout={this.logout}/>
                </div>
                :
                <div>
                    {this.state.loading ?
                        <div className="loading-animation">
                            <Lottie
                                config={{
                                    animationData: animation,
                                    autoplay: true,
                                    loop: true,
                                }}
                            />
                            <p style={{textAlign: 'center'}}className="title">Signing you in...</p>
                        </div>
                    :
                        <div>
                            <div className="landing-page__intro">
                                <h2 className="landing-page__intro-header">Self-care should be fun.</h2>
                                <h4 className="landing-page__intro-subheader">(Even on the web <span role="img" aria-label="face with hearts">🥰</span>)</h4>
                                <p className="landing-page__intro-bullet">Fresh, fun questions every day</p>
                                <p className="landing-page__intro-bullet">Guided content on focus, wind downs, and more </p>
                            </div>
                            <div id="firebaseui-auth-container"></div>
                            <div className="landing-page__img-container">
                                <img className="landing-page__img-dude"src="dude.svg" alt="wellnest-character"/>
                                <img className="landing-page__img-little-blobs"src="little-blobs.svg" alt="small-clouds"/>
                                <img className="landing-page__img-big-blobs"src="big-blobs.svg" alt="big-clouds"/>
                            </div>
                        </div>
                    }
                </div>
                
                }
            </div>
        )
  }
}

export default App