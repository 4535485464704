import React, {Component} from 'react'
import { Card } from 'react-bootstrap'
import { storage } from '../../firebase.js'
import './../../App.css'
import './PackCard.css'

class PackCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: props.pack.title,
            altText: props.pack.alttext,
            description: props.pack.description,
            questions: props.pack.questions,
        }
        this.componentDidMount = this.componentDidMount.bind(this)
    }

    componentDidMount(){
        storage.ref(this.props.pack.image).getDownloadURL()
        .then((url) => {
            this.setState({
                imageUrl: url
            })
        })
        .catch((error) => {
            // console.log(error)
        })
    }

    onClick = () => {
        if(this.props.homescreen){
            this.props.homescreen(2, this.state);
        } else {
            this.props.onSelect(this.props.id)
        }
    }

    render(){
        return (
            <div className="card-container">
                <Card style={{width: '18rem'}} className="pack-card" onClick={this.onClick}>
                    <Card.Img variant="top" src={this.state.imageUrl} alt={this.state.altText} title={this.state.description} style={{borderRadius: '10px'}}/>
                </Card>
                <div className='subCardInfo'>
                    <span style={{'fontWeight': '500', 'fontSize':'18px', 'flexGrow': 8}}>{this.state.title}</span>
                </div>
                
                <p style={{'fontSize': '12','fontWeight': '300'}}>{this.state.questions.length} Questions</p>
            </div>
            
        )
    }
}

export default PackCard