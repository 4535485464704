import React, {Component} from 'react'
import { Card } from 'react-bootstrap'
import EntryFlow from '../entry-flow/EntryFlow.js'
import './../../App.css'
import './PackView.css'
import { storage } from '../../firebase.js'


class PackView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: props.pack.title,
            altText: props.pack.alttext,
            description: props.pack.description,
            questions: props.pack.questions,
            checkin: false,
        }
        this.onClick = this.onClick.bind(this)
    }

    componentDidMount() {
        storage.ref(this.props.pack.image).getDownloadURL()
        .then((url) => {
            this.setState({
                imageUrl: url
            })
        })
        .catch((error) => {
            // console.log(error)
        })
    }

    onClick(arg){
        this.setState({
            checkin: arg,
        })
    }

    render(){
        if(!this.state.checkin) 
        {
            return (
                <div>
                    <div className="back-button-explore" onClick={() => this.props.backButton(null)}>
                        <img src='Back.svg' width='10px' alt="Back button icon." />
                        <span className='back-text'>Back</span>
                    </div>
                    <div className="pack-detail">
                        <Card className="pack-view">
                            <Card.Img src={this.state.imageUrl} alt={this.state.altText} style={{borderRadius: '10px'}}/>
                        </Card>
                        <div className="pack-info">
                            <p className="title">{this.state.title}</p>
                            <p>{this.state.questions.length ? this.state.questions.length : 3} Questions</p>
                            <p>{this.state.description}</p>
                            <button className="atb-3d-b atb-small atb-round atb-danger" onClick={() => this.onClick(1)}>Check In</button>
                        </div>
                    </div>
                </div>
                
            )
        }
        else 
        {
            return (
                <EntryFlow onClick={this.onClick} mode="mini-pack" pack={this.state}/>
            )
        }
    }
}

export default PackView