// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
var firebase = require("firebase/app");
var firebaseui = require('firebaseui');

// Add the Firebase products that you want to use
require("firebase/auth");
require("firebase/firestore");
require("firebase/analytics")
require("firebase/storage")

const firebaseConfig = {
    apiKey: "AIzaSyDJFa6jLVq_3qYKJ8tLweUdQikuHhmb9aw",
    authDomain: "wellnest-web.firebaseapp.com",
    databaseURL: "https://wellnest-web.firebaseio.com",
    projectId: "wellnest-web",
    storageBucket: "wellnest-web.appspot.com",
    messagingSenderId: "135041089457",
    appId: "1:135041089457:web:8a87e49a9a9399579a4790",
    measurementId: "G-S28Q49EHKT"
};

firebase.initializeApp(firebaseConfig);

var ui = new firebaseui.auth.AuthUI(firebase.auth());
ui.start('#firebaseui-auth-container', {
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    // Other config options...
});


export const firestore = firebase.firestore
export const db = firebase.firestore()
export const provider = new firebase.auth.GoogleAuthProvider()
export const auth = firebase.auth()
export const analytics = firebase.analytics()
export const storage = firebase.storage()