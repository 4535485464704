import React, {Component} from 'react'
import { storage } from '../../firebase.js'
import './TopicCard.css'


class TopicCard extends Component {
    constructor(props){
        super(props);
        this.state = {
            title: props.topic.title,
            author: props.topic.author,
            description: props.topic.description,
            questions: props.topic.sets[Math.floor(Math.random() * props.topic.sets.length)]['prompts'],
        }
    }

    componentDidMount(){
        storage.ref(this.props.topic.image).getDownloadURL()
        .then((url) => {
            this.setState({
                imageURL: url
            })
        })
        .catch((error) => {
            console.log(error)
        })
    }

    render(){
        return(
            <div className="topic-card">
                <div className="image-cropper">
                    <img src={this.state.imageURL} alt="" title={this.state.description} className="rounded" onClick={() => this.props.onClick(4, this.state)}/>
                </div>
                <div className='topic-info'>
                    <p style={{'fontWeight': '500', 'fontSize':'18px', 'flexGrow': 8}}>{this.state.title}</p>
                </div>
            </div>
        )
    }

}

export default TopicCard;