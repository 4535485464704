import React, {Component} from 'react'
import { db, auth, analytics } from '../../firebase.js';
import './MoodMusic.css'

class MoodMusic extends Component{
    constructor(){
        super()
        this.state = {
            moods: ['Grateful','Confident','Happy','Nostalgic','Calm', 'Tired','Bored','Sad','Anxious','Angry'],
            selected: null,
        }
        this.onClick = this.onClick.bind(this)
        this.getGreeting = this.getGreeting.bind(this)
        this.getSong = this.getSong.bind(this)
    }
    
    onClick(mood){
        this.setState({
            selected:mood
        })
        db.collection('users').doc(auth.currentUser.uid).collection('entries')
            .doc(this.props.id)
            .update({
                mood: mood.toLowerCase(),
            })
    }

    getGreeting(){
        let mood = this.state.selected
        if(mood === 'Grateful' || mood === 'Confident' || mood === 'Happy' || mood === 'Calm'){
            return "Keep it going."
        } else if(mood === 'Nostalgic' || mood === 'Tired' || mood === 'Bored'){
            return "We've all been there."
        } else {
            return "That's okay. Things happen."
        }
    }

    getSong(){
        let mood = this.state.selected.toLowerCase()
        analytics.logEvent('song_click',{
            mood: mood,
        })
        db.collection('songs').where('mood', '==', mood)
        .get()
        .then((snapshot) => {
            let randomIndex = Math.floor(Math.random()*snapshot.docs.length)
            let doc = snapshot.docs[randomIndex];
            let link = doc.data().link
            if(doc){
                window.open(link, '_blank')
            }
        })

    }

    render(){
        if(this.state.selected != null){
            return (
                <div>
                    <div className="mood-title">
                        <p className="title">Mood Check-In</p>
                        <p>You're feeling {this.state.selected.toLowerCase()}. {this.getGreeting()}</p>
                    </div>
                    <div className="dude-container">
                        <div className="mood-dude select">
                            <img style={{display: 'block', margin:'auto'}} width="200px" height="200px" src={process.env.PUBLIC_URL +'/MoodDudes/' + this.state.selected.toLowerCase() + '.svg'} alt="mood character drawing" />
                        </div>
                        {/* <p className="music-explain">Listen to a song for this mood, chosen by our users.</p>
                        <div className="mood-subinfo" onClick={this.getSong}>
                            <img src="./Music.svg" alt="music icon" width="15px" />
                            <span className="sub-text">{this.state.selected}</span>
                            <img src="./Forward.svg" alt="select icon" height="15px" />
                        </div> */}
                    </div>
                            
                </div>
                
            )
        } else {
            return (
                <div>
                    <div className="mood-title">
                        <p className="title">Mood Check-In</p>
                        <p>How are you feeling? Attach a mood to this entry.</p>
                    </div>
                    <div className="dudes-container">
                        {this.state.moods.map(mood => (
                            <div className="dude-container" key={mood}>
                                <div className="mood-dude">
                                    <img style={{display: 'block', margin:'auto'}} onClick={()=> this.onClick(mood)} width="100px" height="100px" src={process.env.PUBLIC_URL + '/MoodDudes/' + mood.toLowerCase() + '.svg'} alt="mood character drawing" />
                                </div>
                                <span className="sub-text">{mood}</span>
                            </div>
                        ))}
                    </div>
                </div>
                
            )
        }
        
    }
}

export default MoodMusic