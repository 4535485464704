import React, {Component} from 'react'
import { auth, db } from '../../firebase.js'
import PackCard from './PackCard'
import '../../App.css';
import './PinnedHabits.css'

class PinnedHabits extends Component {
    constructor(){
        super()
        this.state = {
            packs: [],
        }
    }

    componentDidMount(){
        // Get recent packs
        const uid = auth.currentUser.uid;
        db.collection('users').doc(uid).collection('entries')
        .where('type', '==', 'mini-pack')
        .orderBy('date', 'desc')
        .limit(6)
        .get()
        .then((snapshot) => {
            snapshot.forEach((doc) => {
                const title = doc.data().title;
                db.collection('mini-packs').doc(title)
                .get()
                .then((doc) => {
                    if (doc.exists){
                        this.setState((prevState) => ({
                            packs: prevState.packs.concat(doc.data())
                        }))
                    }
                })
            })
        })
    }

    render(){
        return (
            <div>
                <p className="title">Recent Singles</p>
                {this.state.packs.length > 0 ? 
                    <p>Keep your practice going.</p>
                    :
                    <p>Singles will collect here to help you build your practice.</p>
                }
                <div className="pinned-habits">
                    {this.state.packs.map((pack, i) => {
                        return (
                            <div key={i}>
                                <PackCard id={i} homescreen={this.props.onClick} pack={pack} />
                            </div>
                        )
                    })}
                </div>
            </div>
            
        )
        
    }
}

export default PinnedHabits