import React, {Component} from 'react'
import Dashboard from './Dashboard.js'
import EntryFlow from '../entry-flow/EntryFlow.js'
import DetailView from './DetailView.js'
import '../../App.css'

class HomeContainer extends Component {
    constructor(){
        super()
        this.state = {
            renderView: 0,
            questions: [],
            title: '',
        }
        this.buttonClick = this.buttonClick.bind(this);
    }

    buttonClick(page, object){
        this.setState({
            renderView: page,
            packObject: object,
        })
    }

    render(){
        switch(this.state.renderView){
            case 0:
                // HOME SCREEN
                return (
                    <div>
                        <Dashboard onClick={this.buttonClick}/>
                    </div>
                )
            case 1:
                // DAILY CONVERSATION
                return (
                    <div>
                        <EntryFlow onClick={this.buttonClick} mode="daily-question" pack={this.state.packObject}/>
                    </div>
                )
            case 2:
                // SINGLE
                return (
                    <div>
                        <EntryFlow onClick={this.buttonClick} mode="mini-pack" pack={this.state.packObject} />
                    </div>
                )
            case 3:
                // DETAIL VIEW OF ENTRY
                return (
                    <div>
                        <DetailView onClick={this.buttonClick} entries={this.state.packObject.entries} title={this.state.packObject.type}/>
                    </div>
                )
            case 4:
                // TOPIC
                return (
                    <div>
                        <EntryFlow onClick={this.buttonClick} mode="topic" pack={this.state.packObject}/>
                    </div>
                )
            default:
                return null
        }
    }
}

export default HomeContainer