import React, {Component} from 'react'
import { auth } from '../../firebase.js'
import Card from 'react-bootstrap/Card'
import '../../App.css'
import './DetailView.css'

class DetailView extends Component {
    constructor(props){
        super(props)
        this.state = {
            uid: auth.currentUser.uid,
            backScreen: this.props.profile ? -1 : 0,
        }
    }

    render(){
        return (
            <div style={{width: "100%"}}>
                <div>
                    <span className="back-button detail" onClick={() => this.props.onClick(this.state.backScreen)}>
                        <img src='Back.svg' width='10px' alt="Back button icon." />
                        <span className='back-text'>Back</span>
                    </span>
                    <Card style={{ width: 'auto'}}>
                        <Card.Body>
                            <Card.Title>{this.props.title}</Card.Title>
                            {this.props.entries.map((entry, i) => (
                                <div className="entry" key={i}>
                                    <p className="subtitle">{entry.question}</p>
                                    <Card.Text style={{marginBottom: '.4rem'}}className="card-text">{entry.response}</Card.Text>
                                </div>
                            ))}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        )
    }

}

export default DetailView