import React, {Component} from 'react'
import { auth, db} from '../../firebase.js'
import AES from 'crypto-js/aes'
import enc from 'crypto-js/enc-utf8'
import Card from 'react-bootstrap/Card'
import moment from 'moment'
import DetailView from '../home/DetailView'
import '../../App.css';
import './Profile.css'

class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            entries: [],
            numWords: 0,
            totalEntries: 0,
            selected: -1,
        }
        this.onClick = this.onClick.bind(this)
        this.updateCounts = this.updateCounts.bind(this)
    }

    updateCounts () {
        var wordcount = 0
        var totalEntries = 0
        this.state.entries.forEach(entryGroup => {
            totalEntries += 1
            entryGroup.entries.forEach(entry => {
                wordcount += entry.response.split(/\S+/g).length
            })
        })
        this.setState({numWords: wordcount, totalEntries: totalEntries})
    }

    componentDidMount(){
        var user = auth.currentUser;
        var uid = user.uid;

        db.collection('users').doc(uid).collection('entries')
            .orderBy("date", "desc")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let type = doc.data().type;
                    if(type === 'daily-question'){
                        type = 'Daily Conversation';
                    } else if(type==='free-thought'){
                        type = 'Free Thought';
                    } else {
                        type = doc.data().title;
                    }
                    let entries = doc.data().responses;
                    entries.forEach(entry => {
                        entry.response = AES.decrypt(entry.response.toString(), uid).toString(enc)
                    })
                    let raw_date = doc.data().date.toDate();
                    let human_date = moment(raw_date).fromNow();
                    this.setState(prevState => ({
                        entries: prevState.entries.concat({type: type, entries: entries, date: human_date, id: doc.id, mood: doc.data().mood ? doc.data().mood : ""}),
                    }));
                });
            })
            .then(() => {
                this.updateCounts()
            })
    }

    onClick(selected) {
        this.setState({
            selected: selected,
        })
    }

    render(){
        if(this.state.selected === -1)
        {
            return (
                <div className="profile">
                    <div className="topbar">
                        <p className="title">{auth.currentUser.displayName.split(" ")[0]}</p>
                        <p className="logout" onClick={this.props.logout}>Log Out</p>
                    </div>
                    <div className="profile-container">
                        <div className="stats-container">
                            <div className="stats-col">
                                <p className="stat">{this.state.numWords}</p>
                                <p>Words Written</p>
                            </div>
                            <div className="stats-col">
                                <p className="stat">{this.state.totalEntries}</p>
                                <p>Total Entries</p>
                            </div>
                        </div>
                        <div className="entries">
                            {this.state.entries.map((entry, i) => (
                                <div key={entry.id}>
                                    <Card className="profile_entry" onClick={() => this.onClick(i)}>
                                        <div className="entry-info">
                                            <div className="entry-title">{entry.type}</div>
                                            <div className="entry-date">{entry.date}</div>
                                        </div>
                                        <div className="mood-info">
                                            {entry.mood &&
                                                <img src={process.env.PUBLIC_URL + '/MoodDudes/' + entry.mood + '.svg'} width="30px" alt="mood character drawing" />
                                            }
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </div>
                        
                    </div>
                </div>
            )
        }
        else
        {
            return <DetailView profile={true} onClick={this.onClick} entries={this.state.entries[this.state.selected].entries} title={this.state.entries[this.state.selected].type} />
        }
    }
}

export default Profile