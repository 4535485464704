import React, {Component} from 'react'
import { db, auth } from '../../firebase.js'
import TopicCard from './TopicCard.js';
import '../../App.css';
import './Topics.css'

class Topics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topics: [],
        }
    }

    componentDidMount(){
        db.collection('users').doc(auth.currentUser.uid).collection('entries')
        .where('type', '==', 'topic')
        .orderBy('date', 'desc')
        .limit(6)
        .get()
        .then((snapshot) => {
            snapshot.forEach((doc) => {
                const title = doc.data().title;
                db.collection('topics')
                .where('title', '==', title)
                .limit(1)
                .get()
                .then((snapshot) => {
                    let doc = snapshot.docs[0]
                    if (doc.exists){
                        this.setState((prevState) => ({
                            topics: prevState.topics.concat(doc.data())
                        }))
                    }
                })
            })
        })
    }

    render() {
        return (
            <div>
                <p className="title">Active Conversations</p>
                {this.state.topics.length > 0 ? 
                    <p>A fresh batch of questions has arrived.</p>
                    :
                    <p>Start conversations below and we'll get you new questions every day.</p>
                }
                <div className="topic-container">
                    {this.state.topics.map((topic, i) => (
                        <TopicCard key={i} topic={topic} onClick={this.props.onClick} />
                    ))}
                </div>
            </div>
        )
    }

}

export default Topics