import React, {Component} from 'react'
import { db, auth } from '../../firebase.js'
import RecentEntries from './RecentEntries.js'
import RecentTopics from './RecentTopics.js'
import Topics from './Topics.js'
import PinnedHabits from './PinnedHabits.js'
import '../../App.css'
import './Dashboard.css'
import PackContainer from './PackContainer.js'

class Dashboard extends Component {
    constructor() {
        super()
        this.state = {
            daily: null,
            packs: []
        }
    }

    componentDidMount(){
        db.collection('daily-question')
        .get()
        .then((snapshot) => {
            let random = Math.floor(Math.random() * snapshot.docs.length)
            let doc = snapshot.docs[random];
            if (doc) {
                this.setState({
                    daily: doc.data(),
                })
            }
        });

        db.collection('mini-packs').get().then((snapshot) => {
            let library = snapshot.docs.map((pack) => {
                return pack.data()
            })

            this.setState({
                packs: library,
            })
        })

    }

    render(){
        if(this.state.daily) {
            return(
                <div>
                    <div className="journal-container">
                        <div className = "daily-question">
                            <p className="title">Hey, {auth.currentUser.displayName.split(" ")[0]}</p>
                            <p className="primer">{this.state.daily.primer}</p>
                            <div className="daily-entry-buttons">
                                <button style={{display: 'flex', alignItems:'center'}} className="atb-3d-b atb-large atb-round atb-danger" onClick={() => this.props.onClick(1, this.state.daily)}>
                                <span>Questions of the Day</span>
                                <img style={{margin:'auto', paddingLeft: '10px'}} width="30px" height="30px" src={'./shuffle.svg'} alt="shuffle icon" />
                                </button>
                            </div>
                            <hr/>
                            <RecentTopics onClick={this.props.onClick} />
                            <hr/>
                            <PinnedHabits onClick={this.props.onClick}/>
                            <hr/>
                            <Topics onClick={this.props.onClick}/>
                            <hr/>
                            <PackContainer packs={this.state.packs}/>
                        </div>
                        <RecentEntries onClick={this.props.onClick}/>
                    </div>
                </div>
                
            )
        } else {
            return null
        }
    }
}

export default Dashboard